exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bet-365-js": () => import("./../../../src/pages/bet365.js" /* webpackChunkName: "component---src-pages-bet-365-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactivebrokers-js": () => import("./../../../src/pages/interactivebrokers.js" /* webpackChunkName: "component---src-pages-interactivebrokers-js" */),
  "component---src-pages-kraken-js": () => import("./../../../src/pages/kraken.js" /* webpackChunkName: "component---src-pages-kraken-js" */),
  "component---src-pages-trading-212-js": () => import("./../../../src/pages/trading212.js" /* webpackChunkName: "component---src-pages-trading-212-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

